import { useQuery, useLazyQuery, useMutation } from '@apollo/react-hooks';
import { Alert, Button, Checkbox, Container, FormControlLabel, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import styles from '../../../CreditApplicationFormWrapper.module.css';
import { LenderProfile, RuleType, SectionType, Task } from "../../../components/types";
import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  checkIfCoApplicantSection,
  checkIfIsOwnersSection,
  checkIfIsSignaturesSection,
  checkIfPersonalGuaranteeSignaturesSection,
  checkIfRulesSatisfied,
  getURLParameter,
  hasFieldsErrors
} from '../../../helpers';
import { S3_BUCKET_NAME } from '../../../config';
import {
  Q_CREDIT_APP,
  UPDATE_TASK_STATUS,
  UPDATE_CREDIT_APPLICATION_V2_QUERY,
  apiMapping,
} from '../../../api';

import _ from 'lodash';
import { Formik, FormikProps, FormikValues, useFormikContext } from 'formik';
import { CommonDataContext, VendorContext } from '../../../context';
import { ApplicationType, BusinessOwnerInput, Maybe, Scalars, TaskStatuses } from '../../../api/types';
import { Constants } from '../../../constants';
import { formatCreditAppToFieldTypes } from '../../../api/formatCreditAppToFieldTypes';
import { OCAv4Section } from '../../OCAv4Section';

const useStyles = makeStyles(() => {
  return {
    containerRoot: {
      backgroundColor: 'white',
      marginTop: "20px",
      marginBottom: "20px",
    },
    submitButton: {
      '&.MuiButton-root': {
        display: 'block',
        margin: '40px auto 0',
        padding: '8px 40px',
      }
    },
    creditAppSummaryContainer: {
      marginBottom: '50px',
      '& .MuiInputBase-root': {
        '&:before, &:after': {
          border: 'none !important',
        }
      },
      '& .MuiSelect-icon, & .MuiFormHelperText-root': {
        display: 'none'
      },
      '& .MuiInputLabel-root': {
        //color: '#00000061 !important',
        color: 'black !important',
      }
    },
    guarantorFormContainer: {
      /*       '& h3': {
              margin: '0',
            } */
      '& h3': {
        marginTop: 60,
      }
    },
    guarantorFormHeader: {
      margin: '0',
    }
  };
});

type CreditApp = {
  id: Maybe<Scalars['String']>;
  creditApplicationOwner?: Maybe<Array<Maybe<BusinessOwnerInput>>>;
};

type FormikInitialValues = {
  owners?: Maybe<Array<Maybe<BusinessOwnerInput>>>;
};

export const OwnerAuthorizationPage = ({
  task,
  publicAccount,
  activeTemplate,
}: {
  task: any;
  publicAccount: any;
  activeTemplate: any;
}) => {

  const classes = useStyles();
  const { logo: vendorLogo } = useContext(VendorContext);
  const { setHighlightErrorFieldsState } = useContext(CommonDataContext);
  const [creditApp, setCreditApp] = useState<CreditApp>();
  const [isIndividualApp, setIsIndividualApp] = useState(false);
  const [isCoApplicant, setIsCoApplicant] = useState(false);
  const [isTaskCompleted, setIsTaskCompleted] = useState(task.status === TaskStatuses.Complete);
  const [ownerToAuthorize, setOwnerToAuthorize] = useState<BusinessOwnerInput>();
  const [sectionsToFill, setSectionsToFill] = useState<SectionType[]>([]);
  const [formikInitialValues, setFormikInitialValues] = useState<FormikInitialValues>({});
  const creditAppId = getURLParameter('creditAppId');
  const OWNER_IDX = 0;

  const [
    queryCreditAppById,
    { data: creditAppData },
  ] = useLazyQuery(Q_CREDIT_APP);

  const [updateCreditAppRequest] = useMutation(UPDATE_CREDIT_APPLICATION_V2_QUERY, {
    context: { authRequired: true },
  });

  const [updateTaskStatus] = useMutation(UPDATE_TASK_STATUS, {
    onCompleted() {
      setIsTaskCompleted(true);
    },
    onError() {
      setIsTaskCompleted(false);
    },
  });

  useEffect(() => {
    if (creditAppId) {
      queryCreditAppById({
        variables: { id: creditAppId },
      });
    }
  }, [creditAppId]);

  useEffect(() => {
    if (!creditAppData || !activeTemplate) {
      return;
    }
    const isIndividualApp = _.toUpper(_.get(creditAppData.creditApplication, 'applicationType')) === ApplicationType.Individual;
    const jsonDefinitionCloned = _.cloneDeep(activeTemplate.jsonDefinition) // need to clone to not modify original
    const allSections = _.flatMap(jsonDefinitionCloned, 'sections');
    const formattedCreditApp = formatCreditAppToFieldTypes(creditAppData.creditApplication, activeTemplate.jsonDefinition);

    const isPrimaryContact = formattedCreditApp.primaryContact.ownerPgId === task.ownerPgId;
    const ownerToAuthorize = isPrimaryContact
      ? formattedCreditApp.primaryContact
      : _.find(formattedCreditApp.owners, { ownerPgId: task.ownerPgId });
    const isCoApplicant = isIndividualApp && ownerToAuthorize.ownerNumber !== 1;

    const formikInitialValues = { owners: [ownerToAuthorize] };
    const sectionsToFill = getSectionsToFill(
      allSections,
      isIndividualApp,
      isCoApplicant,
      isPrimaryContact,
      formattedCreditApp,
      ownerToAuthorize
    );

    setCreditApp(creditAppData.creditApplication);
    setFormikInitialValues(formikInitialValues);
    setOwnerToAuthorize(ownerToAuthorize);
    setIsIndividualApp(isIndividualApp);
    setIsCoApplicant(isCoApplicant);
    setSectionsToFill(sectionsToFill);
  }, [creditAppData, activeTemplate]);


  const getSectionsToFill = (
    allSections: any,
    isIndividualApp: boolean,
    isCoApplicant: boolean,
    isPrimaryContact: boolean,
    formattedCreditApp: any,
    ownerToAuthorize: BusinessOwnerInput
  ) => {

    const getIndividualSection = (allSections: any, isCoApplicant: boolean) => {
      const coApplicantSections = _.filter(allSections, checkIfCoApplicantSection);
      const individualSectionToShow = isCoApplicant
        ? _.find(coApplicantSections, s => _.some(s.fields, f => f.config.fieldName === 'owners[1].signatureDate'))
        : _.find(allSections, s => _.some(s.fields, f => f.config.fieldName === 'signatureDate'));
      _.forEach(individualSectionToShow?.fields, f => {
        // remove complex key for co-applicant setcions
        f.config.fieldName = f.config.fieldName.slice(f.config.fieldName.lastIndexOf(".") + 1);
        _.forEach(f.rules, r => {
          r.targetName = r.targetName.slice(r.targetName.lastIndexOf(".") + 1);
        });
      });
      return individualSectionToShow;
    };

    // That approach determines section to show by rules. Such approach may not work if the task is used for pg/non pg contact
    /*     const getOwnershipSection = (allSections: any, isPrimaryContact: boolean, formattedCreditApp: any) => {
          const ownershipSections = _.filter(allSections, checkIfIsOwnersSection);
          const ownershipSectionToShow = isPrimaryContact
            ? _.find(allSections, s => _.some(s.fields, f => f.config.fieldName === 'signatureDate'))
            : _.find(ownershipSections, s => {
              const defaultRules = _.filter(s.rules, (rule: RuleType) => !rule.type || rule.type === 'default');
              return checkIfRulesSatisfied(formattedCreditApp, defaultRules, s.rulesSatisfactionType);
            }) || _.first(ownershipSections);
          return ownershipSectionToShow;
        }; */

    const getOwnershipSection = (allSections: any, isPrimaryContact: boolean, formattedCreditApp: any) => {
      const ownershipSections = _.filter(allSections, checkIfIsOwnersSection);
      let ownershipSectionToShow = ownerToAuthorize.isOwnerPg
        ? _.find(ownershipSections, s => _.some(s.fields, f => f.config.fieldName === 'ssn'))
        : _.find(ownershipSections, s => !_.some(s.fields, f => f.config.fieldName === 'ssn'));

      if (!ownershipSectionToShow) {
        const sectionAsDefault = ownershipSections[0];
        ownershipSectionToShow = {
          ...sectionAsDefault,
          fields: _.filter(sectionAsDefault.fields, (f) => _.includes(['firstName', 'lastName', 'email'], f.config.fieldName)),
          rules: []
        };
      }
      return ownershipSectionToShow;
    };

    const ownerInfoSection = isIndividualApp
      ? getIndividualSection(allSections, isCoApplicant)
      : getOwnershipSection(allSections, isPrimaryContact, formattedCreditApp);

    _.set(ownerInfoSection, 'title.config', null);
    _.set(ownerInfoSection, 'title.type', 'owners-array'); // process as ownership section

    // todo: if no ownerInfoSection - add simple defaultwith FN, LN, Email

    const dynamicSignatureAndTitleSection = _.find(allSections, checkIfIsSignaturesSection);
    dynamicSignatureAndTitleSection.fields = _.filter(dynamicSignatureAndTitleSection.fields, f => {
      return f.config.fieldName === 'dynamicSignatureAndTitle';
    });

    const personalGuaranteeSection = _.find(allSections, checkIfPersonalGuaranteeSignaturesSection);
    if (personalGuaranteeSection) {
      personalGuaranteeSection.fields = _.filter(personalGuaranteeSection.fields, f => {
        return _.includes(['htmlOutput', 'dynamicSignatureAndTitle'], f.config.fieldName);
      });
      _.forEach(personalGuaranteeSection.fields, f => _.set(f, 'rules', []));
    }

    const isNeedToShowPersonalGuaranteeSection = !isPrimaryContact &&
      personalGuaranteeSection &&
      !ownerToAuthorize.personalGuaranteeSignature;
    const sectionsToFill = _.compact([
      ownerInfoSection,
      (isNeedToShowPersonalGuaranteeSection ? personalGuaranteeSection : null),
      (ownerToAuthorize.signature ? null : dynamicSignatureAndTitleSection)
    ]);

    _.forEach(sectionsToFill, s => _.set(s, `config.displayed`, true));
    return sectionsToFill;
  };


  const getPageTitleLayout = () => {
    if (!isIndividualApp) {
      return <Typography variant="h3">Owner Authorization</Typography>;
    }
    return isCoApplicant
      ? <Typography variant="h3">Co-applicant Authorization</Typography>
      : <Typography variant="h3">Applicant Authorization</Typography>;
  };

  const getCreditApplicationSummaryLayout = () => {
    return <div className={classes.creditAppSummaryContainer}>
      {/* <h2>Credit Application Summary</h2> */}
      <p><b>Company Name:</b> {publicAccount.name}</p>
      <p><b>Company Address:</b> {publicAccount.address}</p>
    </div>
  };


  const submit = async (errors: any, values: any) => {
    setHighlightErrorFieldsState({ state: false });
    if (hasFieldsErrors(errors)) {
      setHighlightErrorFieldsState({ state: true });
      return;
    }

    try {
      const mappedValues = apiMapping({
        values,
      });

      const ownerToSave = _.first(mappedValues.owners) || {};
      _.forEach(ownerToSave, (value, key) => {
        if (_.isNull(value)) {
          _.set(ownerToSave, key, undefined); // set undefined in order to not overwrite existing values with null
        }
      });

      const input = {
        ...((isIndividualApp && !isCoApplicant) && { signature: ownerToSave.signature, personalGuaranteeSignature: ownerToSave.personalGuaranteeSignature }),
        owners: [ownerToSave]
      };

      console.log(input);
      const result = await updateCreditAppRequest({ variables: { creditAppId: creditApp?.id, input } });

      if (_.get(result, 'data.updateCreditApplicationV2')) {
        await updateTaskStatus({
          variables: {
            taskId: task.taskId,
            status: TaskStatuses.Complete
          },
        });
      }
    } catch (error: any) {
      const networkError = _.get(error, 'networkError.result.errors[0].message');
      const message = `Error: ${error.message}. ${networkError ? networkError : ''}.`;
      console.error(message);
    }
  };


  const externalData = {
    isIndividualApp,
    isNeedToOmitOcaSpecificHandlingOwnersLogic: true,
    ownersSectionParams: {
      hideTitle: true,
      hideAddRemoveOwners: true
    },
  };
  return (
    <>
      <Container
        maxWidth="lg"
        className={classes.containerRoot}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {getPageTitleLayout()}
          </Grid>
          {creditApp && ownerToAuthorize && !isTaskCompleted &&
            <Grid item container xs={12}>

              {/*               <Grid item xs={12}>
                {vendorLogo ? (
                  <div className={styles.vendorLogoWrapper}>
                    <img
                      src={
                        vendorLogo.includes('/')
                          ? `https://${S3_BUCKET_NAME}.s3-us-west-2.amazonaws.com/${vendorLogo}`
                          : `https://credit-app-images.s3-us-west-2.amazonaws.com/${vendorLogo}`
                      }
                      alt="Vendor Logo"
                    />
                  </div>
                ) : null}
              </Grid> */}

              <Grid item xs={12}>
                <Formik
                  validateOnChange={false}
                  validateOnBlur={false}
                  validate={() => { }}
                  initialValues={
                    {
                      ...formikInitialValues,
                      whoIsPresentToSign: [OWNER_IDX],
                      whoIsPresentToSignForPersonalGuarantee: [OWNER_IDX],
                    }
                  }
                  onSubmit={() => { }}
                >
                  {({ errors, values }) =>
                    <>
                      {getCreditApplicationSummaryLayout()}
                      <div className={classes.guarantorFormContainer}>
                        <h2 className={classes.guarantorFormHeader}>Authorization Form:</h2>
                        {sectionsToFill.map((section: SectionType, index: number) => {
                          return (
                            <OCAv4Section
                              key={section.title.id}
                              locationId={''}
                              vendorContactId={''}
                              setRepData={() => { }}
                              setRepList={() => { }}
                              config={section}
                              template={activeTemplate}
                              externalData={externalData}
                            />
                          );
                        })}
                      </div>
                      <Button
                        className={classes.submitButton}
                        variant="contained"
                        color="primary"
                        onClick={(e: any) => submit(errors, values)}
                      >
                        Submit
                      </Button>
                    </>
                  }
                </Formik>
              </Grid>
            </Grid>
          }
          {isTaskCompleted && <Grid item xs={12}>
            <Alert severity="success">Authorization has been successfully completed!</Alert>
          </Grid>
          }
        </Grid>
      </Container>
    </>
  );
};
